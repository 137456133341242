@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 6.5rem;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 6.5rem;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}



.container-toast {
  position: fixed;
  z-index: 9999999999;
  bottom: 6.5rem;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 100%;
  min-height: 4.37rem;
  max-width: 17rem;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  border: transparent;
  background-color: white;
  /* box-shadow: $box-shadow; */
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation:
    fadein 0.5s,
    fadeout 0.5s 4.5s;
}

.close_button {
  position: absolute;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.875rem;
  padding: 0.2rem;
  margin-left: 1rem;
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  background-color: #ccc;
  border-radius: 50%;
  transition: all ease-in-out 0.3s;

}
.close_button:hover {
  opacity: 0.5;
  transform: rotate(-25deg);
}
